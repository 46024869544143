import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Last day of test week`}</em></p>
    <p>{`Back Squat 5×1 to a 1RM`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`“The Crippler”`}</strong></p>
    <p>{`30-Back Squats (225/155)`}</p>
    <p>{`1 Mile Run`}</p>
    <p>{`or`}</p>
    <p><strong parentName="p">{`“The San Fransisco Crippler”`}</strong></p>
    <p>{`30-Back Squats (225/155)`}</p>
    <p>{`1K Row`}</p>
    <p>{`for time.`}</p>
    <p><em parentName="p">{`*`}{`compare to 9/20/18`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our next Mobility class will be today at 11:00!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our new class schedule will start Monday, March 17th along with our
new Ville Bootcamp Tues-Friday at 9:30am!  Evening CrossFit classes will
now be offered Monday-Friday at 4:00, 5:00 & 6:00pm.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      